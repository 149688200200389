<template>
  <svg 
  width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M47 10.5L43 6.5" stroke="var(--v-illustrations-darken1)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M43 10.5L47 6.5" stroke="var(--v-illustrations-darken1)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.06859 44.7086V52.3086C7.06859 55.1786 15.5586 57.5086 26.0286 57.5086C36.4986 57.5086 44.9386 55.1986 44.9886 52.3286V44.4286L44.4686 43.8086L7.05859 44.6886V44.7086H7.06859Z" fill="var(--v-illustrations-lighten3)" stroke="var(--v-illustrations-darken1)" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M26.0381 49.4703C36.5081 49.4703 44.9981 47.1403 44.9981 44.2703C44.9981 41.4003 36.5081 39.0703 26.0381 39.0703C15.5681 39.0703 7.07812 41.4003 7.07812 44.2703C7.07812 47.1403 15.5681 49.4703 26.0381 49.4703Z" fill="var(--v-illustrations-lighten3)" stroke="var(--v-illustrations-darken1)" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M7.07031 33.0586V40.5386C7.07031 43.4086 15.5603 45.7386 26.0303 45.7386C36.5003 45.7386 44.9403 43.4286 44.9903 40.5586V33.0586H7.07031Z" fill="var(--v-illustrations-lighten3)" stroke="var(--v-illustrations-darken1)" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M26.0381 37.7086C36.5081 37.7086 44.9981 35.3786 44.9981 32.5086C44.9981 29.6386 36.5081 27.3086 26.0381 27.3086C15.5681 27.3086 7.07812 29.6386 7.07812 32.5086C7.07812 35.3786 15.5681 37.7086 26.0381 37.7086Z" fill="var(--v-illustrations-lighten3)" stroke="var(--v-illustrations-darken1)" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M44.93 21.1289L7 21.1889V28.7289C7 31.5989 15.49 33.9289 25.96 33.9289C36.43 33.9289 44.87 31.6189 44.92 28.7489V21.1289H44.93Z" fill="var(--v-illustrations-lighten3)" stroke="var(--v-illustrations-darken1)" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M25.96 25.8883C36.43 25.8883 44.92 23.5583 44.92 20.6883C44.92 17.8183 36.43 15.4883 25.96 15.4883C15.49 15.4883 7 17.8183 7 20.6883C7 23.5583 15.49 25.8883 25.96 25.8883Z" fill="var(--v-illustrations-lighten3)" stroke="var(--v-illustrations-darken1)" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M41.0581 40.5C41.5981 40.5 42.0381 40.05 42.0381 39.5C42.0381 38.95 41.5981 38.5 41.0581 38.5C40.5181 38.5 40.0781 38.95 40.0781 39.5C40.0781 40.05 40.5181 40.5 41.0581 40.5Z" fill="var(--v-illustrations-darken1)"/>
    <path d="M41.0581 52.5C41.5981 52.5 42.0381 52.05 42.0381 51.5C42.0381 50.95 41.5981 50.5 41.0581 50.5C40.5181 50.5 40.0781 50.95 40.0781 51.5C40.0781 52.05 40.5181 52.5 41.0581 52.5Z" fill="var(--v-illustrations-darken1)"/>
    <path d="M41.0581 28.5C41.5981 28.5 42.0381 28.05 42.0381 27.5C42.0381 26.95 41.5981 26.5 41.0581 26.5C40.5181 26.5 40.0781 26.95 40.0781 27.5C40.0781 28.05 40.5181 28.5 41.0581 28.5Z" fill="var(--v-illustrations-darken1)"/>
    <path d="M55 19.5C56.1 19.5 57 18.6 57 17.5C57 16.4 56.1 15.5 55 15.5C53.9 15.5 53 16.4 53 17.5C53 18.6 53.9 19.5 55 19.5Z" stroke="var(--v-illustrations-darken2)" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M57.8681 47.3383V50.7583C57.8681 51.7783 56.7481 52.6083 55.3581 52.6083H31.5881C30.1981 52.6083 29.0781 51.7783 29.0781 50.7583V47.3383C29.0781 46.3183 30.1981 45.4883 31.5881 45.4883H55.3581C56.7481 45.4883 57.8681 46.3183 57.8681 47.3383Z" fill="var(--v-illustrations-lighten1)" stroke="var(--v-illustrations-darken2)" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M57.8291 54.6703V58.0903C57.8291 59.1103 56.7091 59.9403 55.3191 59.9403H31.5491C30.1591 59.9403 29.0391 59.1103 29.0391 58.0903V54.6703C29.0391 53.6503 30.1591 52.8203 31.5491 52.8203H55.3191C56.7091 52.8203 57.8291 53.6503 57.8291 54.6703Z" fill="var(--v-illustrations-lighten1)" stroke="var(--v-illustrations-darken2)" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M32.98 50.0508C33.52 50.0508 33.96 49.6008 33.96 49.0508C33.96 48.5008 33.52 48.0508 32.98 48.0508C32.44 48.0508 32 48.5008 32 49.0508C32 49.6008 32.44 50.0508 32.98 50.0508Z" fill="var(--v-illustrations-darken2)"/>
    <path d="M32.98 57.5C33.52 57.5 33.96 57.05 33.96 56.5C33.96 55.95 33.52 55.5 32.98 55.5C32.44 55.5 32 55.95 32 56.5C32 57.05 32.44 57.5 32.98 57.5Z" fill="var(--v-illustrations-darken2)"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>